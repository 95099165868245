'use client'

import Text from '@ignition/library/components/atoms/text'

const ErrorPage = () => {
  return (
    <div className="min-h-full bg-background px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <Text className="text-4xl font-bold tracking-tight text-primary sm:text-5xl">500</Text>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <Text as="h1" className="text-4xl font-bold tracking-tight text-text sm:text-5xl">
                Something went wrong
              </Text>
              <Text as="p" className="mt-1 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </Text>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href="/"
                className="inline-flex items-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
              >
                Go back home
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}

export default ErrorPage
